import React, { FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import leftLogo from "../../assets/login-left_logo@2x.png";
import {
  authLanguagesRequest,
  authLangValidateRequest,
  authPicturesRequest,
  authDefaultUnitSystemRequest
} from "../../model/authentication/authentication-actions";
import { EntityLanguage } from "../../model/authentication/authentication-model";
import { MainState } from "../../model/main-model";
import styles from "../../styles/modules/language-choice.module.scss";
import { useRouter } from "../../utils/router-utils";
import { useT } from "../i18n-container";
import { HeaderContainer } from "../header-container";
import { Tra } from "../../components/tra";

type OwnProps = {};

const mapStateToProps = (state: MainState) => ({
  languagesState: state.authentication.languages
});

const mapDispatchToProps = (dispatch: any) => ({
  getLanguages: () => {
    return dispatch(authLanguagesRequest());
  },
  getPictures: () => {
    return dispatch(authPicturesRequest());
  },
  getDefaultUnitSystem: () => {
    return dispatch(authDefaultUnitSystemRequest());
  },
  setLangValidation: (data: EntityLanguage) => {
    return dispatch(authLangValidateRequest(data));
  }
});

type PropsType = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Component = ({
  setLangValidation,
  languagesState,
  getLanguages,
  getPictures,
  getDefaultUnitSystem
}: PropsType) => {
  useEffect(() => {
    getLanguages();
    getPictures();
    getDefaultUnitSystem();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // FIXME
  const languages: EntityLanguage[] =
    languagesState !== undefined ? languagesState["hydra:member"] : [];
  const [languageId, setLang] = useState<string | undefined>(
    languages.find((x: EntityLanguage) => x.isDefault === true)?.["@id"] ??
      languages[0]?.["@id"]
  );
  const { t, ht, pic } = useT();
  const router = useRouter();
  const handleInput = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setLang(value);
  };
  const handleForm = (e: FormEvent) => {
    e.preventDefault();
    const defaultLanguage = languages.find(
      (x: EntityLanguage) => x.isDefault === true
    );

    setLangValidation(
      languages.find(lang => lang["@id"] === languageId) ??
        defaultLanguage ??
        languages[0]
    );
    router.push("/login");
  };

  return (
    <div className={`${styles["container"]} container-fluid`}>
      <HeaderContainer />
      <div className={`${styles["container_row"]} row`}>
        <div
          style={{ backgroundImage: `url(${pic("img-home")})` }}
          className={`${styles["left"]} col-sm-12 col-md-6`}
        >
          <img className={styles.left_logo} src={pic("img-home-logo")} alt="" />
          <div className={styles.left_pic02}>
            <ul>
              <li>
                <span></span> {t("txt-home-list-1")}
              </li>
              <li>
                <span></span> {t("txt-home-list-2")}
              </li>
              <li>
                <span></span> {t("txt-home-list-3")}
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles["right"]} col-sm-12 col-md-6`}>
          <div className={styles.right_title}>
            <img
              src={pic("img-home-title")}
              alt=""
              className={styles.right_img}
            />
            <form onSubmit={handleForm} className={styles.right_form}>
              <div className={`${styles["right_form"]} form-group`}>
                <h3>{ht("lbl-home-select")}</h3>
                <label className={styles.right_label}>
                  {ht("lbl-form-language")}
                </label>
                <select
                  name="languages"
                  id="languages"
                  onChange={handleInput}
                  className="form-control"
                >
                  {languages.map((lang: EntityLanguage, idx: any) => (
                    <option value={lang["@id"]} key={`${lang}-${idx}`}>
                      {lang.code}
                    </option>
                  ))}
                </select>
              </div>
              <button
                disabled={languages.length === 0}
                type="submit"
                className="btn btn-primary"
              >
                {ht("btn-form-language")}
              </button>
            </form>
          </div>
          <img src={pic("img-home-feder")} className={styles.right_feder} />
        </div>
        <div className="ui-languages-footer"></div>
      </div>
    </div>
  );
};

export const LanguageChoice = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
