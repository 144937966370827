import React, {useState} from "react";
import {Route, useHistory} from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import Logout from "../containers/authentication/logout";
import { SaveProject } from "../containers/save-project";
import { UserData } from "../model/authentication/authentication-model";
import { Tra } from "./tra";
import { useT } from "../containers/i18n-container";
import { ReactComponent as IconCall } from "../assets/call-ico.svg";
import { ReactComponent as IconMenu } from "../assets/menu-burger.svg";
import { ReactComponent as IconUser } from "../assets/person-icon.svg";
import { ReactComponent as IconSave } from "../assets/save-icon.svg";
import styles from "../styles/modules/header.module.scss";
import {Sidebar} from "../containers/sidebar";
import {MenuPopupContext} from "../containers/menu-popup-context";

export const Header = ({
  isGuest,
  user,
}: {
  isGuest: boolean;
  user: UserData["user"] | null;
}) => {
  const { t, pic } = useT();

  const [showCallPopup, setShowCallPopup] = useState(false);

  const history = useHistory();

  const redirect = (path: string) => {
    history.push(path);
  }

  return (
    <MenuPopupContext.Consumer>
      {({showMenuPopup, toggleMenuPopup}) => (
        <nav className={`${styles["blue-background"]} main-navbar`}>
          <div className="row justify-content-between">
            <div className={`${styles["right"]}`}>
              <div className={`${styles["header-login"]} row`}>
                <div className={`${styles["header__logo"]}`}>
                  <a href={t("url-www")} target="_blank" className="link-www">
                    <Logo />
                    <Tra code="btn-header-site" />
                  </a>
                </div>
                <div
                  className={`${styles["main-navbar_call-grp"]} main-navbar_call-grp`}
                  onClick={() => setShowCallPopup(!showCallPopup)}
                >
                  <IconCall></IconCall>
                  <span className="main-navbar_question">
                    <Tra code="lbl-header-hotline-1" />
                  </span>
                  <span className="main-navbar_call">
                    <Tra code="lbl-header-hotline-2" />
                  </span>
                  <span className="main-navbar_number">
                    <Tra code="lbl-header-phone" />
                  </span>
                </div>
              </div>
            </div>
            <div className="">
              {" "}
              {!isGuest ? (
                <div className="main-navbar_links">
                  <div className="mr-2">
                    <Route
                      path="/projects/:projectId"
                      render={() => (
                        <SaveProject
                          loginModalText={t("text-login-to-save")}
                          buttonLabel={t("btn-header-save")}
                          connectButtonLabel={t("btn-header-connect-save")}
                          buttonComponent={({ onClick, label }) => (
                            <span>
                              <button className="btn btn-link" onClick={onClick}>
                                <IconSave></IconSave>
                                <span className="main-navbar_save-label">{label}</span>
                              </button>
                            </span>
                          )}
                        />
                      )}
                    />
                  </div>
                  <div className="mr-2">
                    {user ? (
                      <button className={styles["btn-header"]} onClick={() => {
                          toggleMenuPopup(false);
                          redirect('/profile');
                        }}>
                        <IconUser></IconUser>
                        <span className="main-navbar_links_user-pseudo">
                          {" "}
                          {user.firstname} {user.lastname}
                        </span>
                      </button>
                    ) : (
                      <Route
                        path="/main"
                        render={() => (
                          <button className={styles["btn-header"]} onClick={() => redirect('/login')}>
                            <IconUser></IconUser>
                            {" "}
                            <Tra code="btn-connect" />
                          </button>
                        )}
                      />
                    )}
                  </div>
                  <div className={`${styles["logout"]} mr-2`}>
                    <Logout />
                  </div>
                    <div
                      className={`${styles["menu-responsive"]}`}
                      onClick={() => toggleMenuPopup(!showMenuPopup)}
                    >
                      <IconMenu></IconMenu>
                    </div>
                </div>
              ) : null}
            </div>
            { showCallPopup ? (
              <div className={`${styles["header__call-popup"]}`}>
                <IconCall></IconCall>
                <div className={`${styles["header__call-popup-content"]}`}>
                <span className="">
                  <Tra code="lbl-header-hotline-1" />
                </span>
                  <div className="d-flex">
                  <span className={`${styles["header__call-popup-content-text"]}`}>
                    <Tra code="lbl-header-hotline-2" />
                  </span>
                    <span className="">
                    <Tra code="lbl-header-phone" />
                  </span>
                  </div>
                </div>
              </div>
            ) : null }
            { showMenuPopup ? (
              <aside className="popup-sidebar">
                <div className="popup-sidebar_logo">
                  <button onClick={() => {
                    toggleMenuPopup(false);
                    redirect('/main');
                  }}>
                    <img src={pic('img-menu-logo')} alt=""/>
                  </button>
                </div>
                <Route
                  path={[
                    "/projects/:projectId/substeps/:substepId",
                    "/recap/:projectId",
                    ""
                  ]}
                  component={Sidebar}
                />
              </aside>
            ) : null }
          </div>
        </nav>
        )}
    </MenuPopupContext.Consumer>
  );
};
