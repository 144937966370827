import { useEffect, useState } from "react";
import {
  AnyServerOption,
  AnyStepOptions,
  isStdStepOptions,
  StepList,
  shouldSkip
} from "../model/steps/steps-model";
import { fetchOptionsForStep } from "../model/steps/steps-service";
import { Substep } from "../model/substeps/substeps-model";
import {
  collectionToIndexed,
  editEntry,
  Indexed,
  mapEntries
} from "./collection-utils";
import { usePromise } from "./react-utils";
import { subscribeOn } from "rxjs/operators";

export const useStepViewState = <T extends AnyServerOption>(
  substepId: string | null,
  substeps: Indexed<Substep>,
  steps: StepList,
  selectedOptions: AnyStepOptions[] | null,
  onOptionsChange: (options: AnyStepOptions[]) => void,
  onSkip?: (substepId: string, steps: StepList) => void,
  radioMode?: boolean
) => {
  type FormState = Indexed<{
    checked: boolean;
    option: T;
    label: string;
    picture?: string;
    id: string;
    comment?: string;
  }>;

  const [formState, setFormState] = useState<FormState>({});

  const [options, error, pending] = usePromise(
    () => fetchOptionsForStep(substepId, substeps, steps),
    null,
    [substepId]
  );

  const onChange = (optionId: string, checked: boolean) => {
    const newState = radioMode
      ? mapEntries(
          entry => ({
            ...entry,
            checked: checked && optionId === entry.option.id
          }),
          formState
        )
      : editEntry(
          optionId,
          entry => ({
            ...entry,
            checked
          }),
          formState
        ) || formState;

    setFormState(newState);

    onOptionsChange(
      Object.values(newState)
        .filter(entry => entry.checked)
        .map(curr => curr.option)
    );
  };

  useEffect(() => {
    if (!options) {
      return;
    }
    if (onSkip && substepId && options.length === 0) {
      console.log("skipping step");
      if (shouldSkip(substepId, substeps)) {
        onSkip(substepId, steps);
      }
    } else {
      setFormState(
        collectionToIndexed(
          options.map(option => {
            return {
              label: option.label,
              id: option.id,
              picture: option.picture,
              comment: option.comment,
              option: option as T,
              checked:
                selectedOptions && isStdStepOptions(selectedOptions)
                  ? Boolean(selectedOptions.find(opt => opt.id === option.id))
                  : false
            };
          })
        )
      );

      const isNoneOption = options.find(option => option.isNone);
      if ((!selectedOptions || selectedOptions.length) === 0 && isNoneOption) {
        onOptionsChange([isNoneOption]);
      }
    }
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps
  // FIXME

  return [formState, onChange, error, pending] as [
    typeof formState,
    typeof onChange,
    typeof error,
    typeof pending
  ];
};
