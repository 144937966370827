import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Modal} from "react-bootstrap";
import { connect } from "react-redux";
import {Link, useHistory} from "react-router-dom";
import { Dispatch } from "redux";
import { MaskingSpinner } from "../components/masking-spinner";
import { ProjectListEntry } from "../components/project-list-entry";
import { TemplateListEntry } from "../components/template-list-entry";
import { SimpleList } from "../components/simple-list";
import { translate } from "../model/i18n/i18n-model";
import { MainState } from "../model/main-model";
import { fetchProjectsRequest } from "../model/projects/projects-actions";
import { fetchTemplatesRequest } from "../model/templates/templates-actions";
import {
  getProjectList,
  isCurrentProject,
  isFinishedProject,
  ProjectState
} from "../model/projects/projects-model";
import {
  getTemplateList,
  TemplateState
} from "../model/templates/templates-model";
import { getStepList } from "../model/steps/steps-model";
import spinnerStyles from "../styles/modules/masking-spinner.module.scss";
import styles from "../styles/modules/projects-list.module.scss";
import { PropsType } from "../utils/ts-utils";
import { BackBtn } from "../components/back-btn";
import { hasValidToken } from "../model/authentication/authentication-model";
import { Tra } from "../components/tra";
import { ReactComponent as IcoDuplicate } from "../assets/duplicate-icon.svg";
import { ReactComponent as IcoArchive } from "../assets/archive-icon.svg";
import { ReactComponent as IcoTemplate } from "../assets/template-icon.svg";
import { ReactComponent as IcoDelete } from "../assets/bin-ico-notofficial.svg";
import { useT } from "./i18n-container";
import {MainUI} from "../components/main-ui";
import {MenuPopupContext} from "./menu-popup-context";

const mapStateToProps = (state: MainState) => ({
  newProjectLabel: translate("lbl-form-project-name", state),
  substeps: state.substeps.byId,
  hasValidToken: hasValidToken(state.authentication),
  projectList: getProjectList(state.projects, state.authentication.data?.user["@id"]),
  loading: state.projects.dirty,
  steps: getStepList(state.steps),
  templateList: getTemplateList(state.templates),
  hasAuthData: state.authentication.data?.user.country && state.authentication.data?.user.profession
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProjects: () => dispatch(fetchProjectsRequest()),
  fetchTemplates: () => dispatch(fetchTemplatesRequest())
  // addProject: (label: string, steps: StepList, projects: ProjectsState) => {
  //   console.log("ID IS ", getNewProjectId(projects));
  //   dispatch(addProjectRequest(label, getNewProjectId(projects), steps));
  // }
});

const Component = ({
  // addProject,
  newProjectLabel,
  steps,
  fetchProjects,
  fetchTemplates,
  loading,
  hasValidToken,
  substeps,
  projectList,
  templateList,
  hasAuthData
}: PropsType<{}, typeof mapStateToProps, typeof mapDispatchToProps>) => {
  const finishedProjects = projectList.filter(project =>
    isFinishedProject(project, substeps)
  );
  const currentProjects = projectList.filter(proj =>
    isCurrentProject(proj, substeps)
  );

  const [show, setShow] = useState(false);

  const getRender = useMemo(
    () => (state: string) => (entry: ProjectState) => (
      <ProjectListEntry project={entry} state={state} />
    ),
    []
  );

  const getRenderTemplate = useMemo(
    () => () => (entry: TemplateState) => (
      <TemplateListEntry template={entry} />
    ),
    []
  );

  const { t } = useT();

  useEffect(() => {
    if (hasValidToken) {
      fetchProjects();
    }
    fetchTemplates();
    const alertUserInfo = sessionStorage.getItem('alertUserInfo');
    if (!alertUserInfo) {
      sessionStorage.setItem('alertUserInfo', '1');
      setShow(!hasAuthData);
    }
  }, [fetchProjects, fetchTemplates, hasValidToken]);

  const history = useHistory();
  const redirect = (path: string) => {
    history.push(path);
  }

  if (!steps) {
    return null;
  }

  const templateProjects = projectList.filter(project => project.isTemplate);

  const archivedProjects = projectList.filter(project => project.isArchived);

  const genericProjects = templateList;

  return (
    <div className={spinnerStyles.masking_spinner_container}>
      <MaskingSpinner show={loading} text="Chargement..." />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>{t('txt-missing-user-data')}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => history.push(`/profile`)}
          >
            <Tra code="btn-goto-profile" />
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            <Tra code="btn-close" />
          </Button>
        </Modal.Footer>
      </Modal>
      <div></div>
      <BackBtn />
      <h1>
        <Tra code="title-my-projects" />
      </h1>
      <div className="d-flex justify-content-end">
        {/* Dupliquer - Définir comme projet type - Archiver */}
        <button className="btn btn-primary" onClick={() => redirect("/new-project")}>
          <Tra code="btn-new-project" />
        </button>
      </div>
      <section className="mt-5">
        <div className="d-flex justify-content-end">
          <div className={styles["project-legend"]}>
            <IcoDelete /> <span>{t("lbl-delete")}</span>
          </div>
          <div className={styles["project-legend"]}>
            <IcoDuplicate />
            <span>{t("lbl-duplicate")}</span>
          </div>
          <div className={styles["project-legend"]}>
            <IcoArchive />
            <span>{t("lbl-archive")}</span>
          </div>
          <div className={styles["project-legend"]}>
            <IcoTemplate />
            <span>{t("lbl-set-as-template")}</span>
          </div>
        </div>
        <h1 className="h4">
          <Tra code="title-project-pending" />
        </h1>
        {currentProjects.length ? (
          <SimpleList
            className={`${styles.projects_list} list-group mb-5`}
            entryClassName="list-group-item"
            list={currentProjects}
            render={getRender("progress")}
          />
        ) : (
          <Alert variant="light">
            <Tra code="title-project-pending-none" />
          </Alert>
        )}
      </section>
      {hasValidToken ? (
        <section>
          <h1 className="h4">
            <Tra code="title-project-template" />
          </h1>
          {templateProjects.length ? (
            <SimpleList
              className={`${styles.projects_list} list-group mb-5`}
              entryClassName="list-group-item"
              list={templateProjects}
              render={getRender("template")}
            />
          ) : (
            <Alert variant="light">
              <Tra code="title-project-template-none" />
            </Alert>
          )}
        </section>
      ) : null}
      <section>
        <h1 className="h4">
          <Tra code="title-project-generic-template" />
        </h1>
        {genericProjects.length ? (
          <SimpleList
            className={`${styles.projects_list} list-group mb-5`}
            entryClassName="list-group-item"
            list={genericProjects}
            render={getRenderTemplate()}
          />
        ) : (
          <Alert variant="light">
            <Tra code="title-project-archived-none" />
          </Alert>
        )}
      </section>
      <section>
        <h1 className="h4">
          <Tra code="title-project-done" />
        </h1>
        {finishedProjects.length ? (
          <SimpleList
            className={`${styles.projects_list} list-group mb-5`}
            entryClassName="list-group-item"
            list={finishedProjects}
            render={getRender("done")}
          />
        ) : (
          <Alert variant="light">
            <Tra code="title-project-done-none" />
          </Alert>
        )}
      </section>
      { hasValidToken ? (
        <section>
          <h1 className="h4">
            <Tra code="title-project-archived" />
          </h1>
          {archivedProjects.length ? (
            <SimpleList
              className={`${styles.projects_list} list-group mb-5`}
              entryClassName="list-group-item"
              list={archivedProjects}
              render={getRender("archived")}
            />
          ) : (
            <Alert variant="light">
              <Tra code="title-project-archived-none" />
            </Alert>
          )}
        </section>
      ) : null}
    </div>
  );
};

export const ProjectsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
