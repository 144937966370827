import cuid from "cuid";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import {
  appendEntries,
  collectionToIndexed,
  editEntry,
  Indexed,
  removeEntries
} from "../../utils/collection-utils";
import {addProjectToTree, reconstructTree} from "../../utils/substep-utils";
import { isNotNil } from "../../utils/ts-utils";
import { AnyMainAction } from "../main-actions";
import { MainState } from "../main-model";
import {
  addProjectRequest,
  duplicateProjectSuccess,
  fetchProjectSuccess,
  saveProjectError,
  saveProjectSuccess
} from "../projects/projects-actions";
import {
  AnyStepOptions,
  areSameOptions,
  areOptionsFromAIncludedInB,
  getNewSubstepConfigurations,
  isLastStep,
  StepList,
  getOptionsFromBNotIncludedInA,
  getOptionsFromANotIncludedInB
} from "../steps/steps-model";
import {
  deleteSubpart,
  setSubstepOptions,
  validateSubstep
} from "./substeps-actions";
import {duplicateTemplateSuccess} from "../templates/templates-actions";
import {ServerProject, ServerProjectPart} from "../projects/projects-service";
import {formatDate} from "../../utils/date-utils";
import {isEmpty} from "ramda";

export type SubstepCreationData = AnyStepOptions | null;

export interface Substep {
  id: string;
  name: string | null;
  creationData: SubstepCreationData;
  stepLevel: number;
  projectId: string;
  backendProjectId: string | null;
  parent: string | null;
  selectedOptions: AnyStepOptions[] | null;
  completed: boolean;
  substeps?: string[];
}

export interface SubstepState {
  byId: Indexed<Substep>;
}

const initialState: SubstepState = {
  byId: {}
};

export const substepsSelector = (state: MainState) => state.substeps;

export const indexedSubstepsSelector = createSelector(
  substepsSelector,
  substepsState => substepsState.byId
);

export const isSubstepNotCompleted = (substep: Substep) => !substep.completed;

export const isLeaf = (
  substep: Substep,
  steps: StepList,
  partial: boolean = false
) => partial
  // ? substep.selectedOptions === null || isEmpty(substep.selectedOptions) || (substep.selectedOptions[0].type === 'MS' && substep.selectedOptions[0].value === '0')
  ? substep.completed === false || substep?.creationData?.type === 'MS'
  : isLastStep(substep.stepLevel, steps);

export const getProjectLeaves = (
  projectId: string,
  substeps: Indexed<Substep>,
  steps: StepList,
  partial: boolean = false
) =>
  Object.values(getSubstepsForProject(projectId, substeps)).filter(substep =>
    isLeaf(substep, steps, partial)
  );

export const getProjectSubstepDatas = (
  projectId: string,
  substeps: Indexed<Substep>,
  steps: StepList,
  partial: boolean = false
) => {
  return getProjectLeaves(projectId, substeps, steps, partial).map(substep =>
    getSubsetDataWParentsData(substep.id, substeps)
  );
};

export const getProjectSubstepDatasForRecap = (
  projectId: string,
  substeps: Indexed<Substep>,
  steps: StepList
) => {
  //  console.log(getProjectLeaves(projectId, substeps, steps));
  return getProjectLeaves(projectId, substeps, steps).map(substep =>
    getSubsetDataWParentsDataForRecap(substep.id, substeps)
  );
};

export const getCurrentSubstepForProject = (
  projectId: string,
  substeps: Indexed<Substep>
) => {
  const rootSubstep = getRootSubstep(projectId, substeps);
  if (!rootSubstep) {
    // console.log("root substep not found");
    return null;
  }
  return getCurrentSubstep(rootSubstep.id, substeps);
};

export const isProjectDone = (projectId: string, substeps: Indexed<Substep>) =>
  getCurrentSubstepForProject(projectId, substeps) === null;

export const getChildren = (substepId: string, substeps: Indexed<Substep>) =>
  Object.values(substeps).filter(substep => substep.parent === substepId);

/**
 * return every substep that is a descendant of given substep
 * @param substepId
 * @param substeps
 */
export const getAllDescendants = (
  substepId: string,
  substeps: Indexed<Substep>
): Substep[] => {
  const children = getChildren(substepId, substeps);
  return [
    ...children,
    ...children.map(child => getAllDescendants(child.id, substeps)).flat()
  ];
};

/**
 * Return first substep AFTER the tree splits
 * @param substepId
 * @param substeps
 */
export const getClosestSubpartFirstChild = (
  substepId: string,
  substeps: Indexed<Substep>
): Substep => {
  const substep = getSubstep(substepId, substeps);
  if (!substep.parent || substep.stepLevel === 1) {
    return substep;
  }

  const parent = getSubstep(substep.parent, substeps);
  if (getChildren(parent.id, substeps).length > 1) {
    return substep;
  }

  return getClosestSubpartFirstChild(parent.id, substeps);
};

/**
 * get closest substep WHERE tree splits
 * @param substepId
 * @param substeps
 */
export const getSubstepClosestSubpart = (
  substepId: string,
  substeps: Indexed<Substep>
): Substep => {
  const firstChild = getClosestSubpartFirstChild(substepId, substeps);

  return firstChild.parent
    ? getSubstep(firstChild.parent, substeps)
    : firstChild;
};

export const getBottomStepNameLabel = (
  substepId: string,
  substeps: Indexed<Substep>
) => {
  const lastSubstep = getSubstep(substepId, substeps);
  if (lastSubstep.stepLevel > 1) {
    return lastSubstep;
  }

  return undefined;
};

/**
 * Returns all substeps in the subpart of specified substep id
 * @param substepId any substepId in the subpart
 * @returns an array of substeps
 */
export const getSubpartSubsteps = (
  substepId: string,
  substeps: Indexed<Substep>
): Substep[] => {
  const substep = getSubstep(substepId, substeps);
  const firstChild = getClosestSubpartFirstChild(substep.id, substeps);
  const descendants = getAllDescendants(firstChild.id, substeps);
  return [firstChild, ...descendants];
};

export interface SubpartsTree {
  substep: Substep;
  isSubpartsTree: boolean;
  children: SubpartsTree[];
}

export const isSubpartsTree = (
  item: SubpartsTree | Substep
): item is SubpartsTree => (item as SubpartsTree).isSubpartsTree;

export const getSubpartsTree = (
  substepId: string,
  substeps: Indexed<Substep>
): SubpartsTree => {
  const substep = getSubstep(substepId, substeps);
  const children = getChildren(substep.id, substeps);

  if (children.length === 0) {
    return { substep: substep || "", children: [], isSubpartsTree: true };
  }

  const childStuff = children.map(child => {
    const subpart = getSubpartsTree(child.id, substeps);
    // if (subpart.children.length === 0) {
    //   return {
    //     substep: subpart.substep || "",
    //     children: [],
    //     isSubpartsTree: true
    //   };
    // }
    return subpart;
  });

  if (childStuff.length === 1) {
    return {
      substep: substep || "",
      children: childStuff[0].children,
      isSubpartsTree: true
    };
  }

  return {
    substep: substep || "",
    children: childStuff,
    isSubpartsTree: true
  };
};

export const getProjectSubpartsTree = (
  projectId: string,
  substeps: Indexed<Substep>
) => {
  const root = getRootSubstep(projectId, substeps);
  return getSubpartsTree(root.id, substeps);
};

export const shouldDisplaySubstep = (
  substepId: string,
  substeps: Indexed<Substep>
) => {
  const substep = getSubstep(substepId, substeps);
  return substep.stepLevel < 3;
};

export const getRemainingOpenSubstepsForProject = (
  projectId: string,
  substeps: Indexed<Substep>
) => {
  const root = getRootSubstep(projectId, substeps);

  return getRemainingOpenSubsteps(root, substeps);
};

export const getRemainingOpenSubsteps = (
  substep: Substep,
  substeps: Indexed<Substep>
): Substep[] => {
  const startingArray = isSubstepNotCompleted(substep) ? [substep] : [];
  return getChildren(substep.id, substeps).reduce((acc, childSubstep) => {
    return [...acc, ...getRemainingOpenSubsteps(childSubstep, substeps)];
  }, startingArray);
  // const result = isSubstepNotCompleted(substep) ? [substep, ] : [];
};

export const getCurrentSubstep = (
  rootSubstepId: string,
  substeps: Indexed<Substep>
): Substep | null => {
  // if (currentProj.substeps.length === 0) {
  //   return currentProj;
  // }
  const substep = getSubstep(rootSubstepId, substeps);

  if (isSubstepNotCompleted(substep)) {
    return substep;
  }

  return getChildren(substep.id, substeps)
    .sort(
      (a, b) =>
        (a.creationData?.sortOrder ?? 0) - (b.creationData?.sortOrder ?? 0)
    )
    .reduce<Substep | null>((acc, substep) => {
      // console.log(acc);
      return acc ? acc : getCurrentSubstep(substep.id, substeps);
    }, null);
};

export const getSubsetWParents = (
  substepId: string,
  substeps: Indexed<Substep>
): Substep[] => {
  const substep = getSubstep(substepId, substeps);
  return substep.parent
    ? [substep, ...getSubsetWParents(substep.parent, substeps)]
    : [substep];
};

export const getSubsetDataWParentsData = (
  substepId: string,
  substeps: Indexed<Substep>
): SubstepCreationData[] => {
  // const substep = getSubstep(substepId, substeps);
  const substepWParents = getSubsetWParents(substepId, substeps)
    .slice(0, -1)
    .reverse();
  return substepWParents.map(substep => substep.creationData);

  // return substep.parent
  //   ? [substep.creationData, ...getSubsetDataWParentsData(substep.parent, substeps)]
  //   : [substep.creationData];
};

export const getSubsetDataWParentsDataForRecap = (
  substepId: string,
  substeps: Indexed<Substep>
): Substep[] => {
  // const substep = getSubstep(substepId, substeps);
  const substepWParents = getSubsetWParents(substepId, substeps)
    .slice(0, -1)
    .reverse();
  return substepWParents;
  // return substep.parent
  //   ? [substep.creationData, ...getSubsetDataWParentsData(substep.parent, substeps)]
  //   : [substep.creationData];
};

export const selectedOptionsAreEmpty = (substep: Substep) => {
  if (!substep.selectedOptions) {
    return true;
  }

  if (
    substep.selectedOptions.length === 1 &&
    substep.selectedOptions[0].value === null
  ) {
    return true;
  }

  return false;
};

export const getPreviousSubstep = (
  substepId: string,
  substeps: Indexed<Substep>
): Substep | null => {
  const substep = getSubstep(substepId, substeps);

  if (!substep.parent) {
    return null;
  }

  const parent = getSubstep(substep.parent, substeps);

  return selectedOptionsAreEmpty(parent)
    ? getPreviousSubstep(parent.id, substeps)
    : parent;
};

export const getRootSubstep = (projectId: string, substeps: Indexed<Substep>) =>
  Object.values(substeps).find(
    entry => entry.projectId === projectId && entry.stepLevel === 0
  )!;

export const getRootPI = (projectId: string, substeps: Indexed<Substep>) => {
  const rootPIs = Object.values(substeps).filter(
    (p, idx) => p.stepLevel === 1 && p.projectId === projectId
  );

  if (
    rootPIs.length === 1 ||
    rootPIs.filter((p, idx) => p.completed).length === 0
  ) {
    return rootPIs[0];
  }

  return rootPIs.reverse().find(e => e.completed);
};

export const getCurrentTP = (piId: string, substeps: Indexed<Substep>) => {
  const tpCurrents = getChildren(piId, substeps);

  if (
    tpCurrents.length === 1 ||
    tpCurrents.filter((p, idx) => p.completed).length === 0
  ) {
    return tpCurrents[0];
  }

  return tpCurrents.reverse().find(e => e.completed);
};

export const makeUniqueProjectSubstepsSelector = () =>
  createSelector(
    [
      (state: MainState) => state.substeps,
      (_: any, projectId: string) => projectId
    ],
    (substeps, projectId) => getSubstepsForProject(projectId, substeps.byId)
  );

export const isSubstepModified = (
  substepId: string,
  substeps: Indexed<Substep>
) => {
  const substep = getSubstep(substepId, substeps);

  if (!substep.completed) {
    return false;
  }

  const childrenData = mapChildrenToCreationDatas(substep.id, substeps).filter(
    isNotNil
  );

  const selected = substep.selectedOptions;

  if (selected === null) {
    return childrenData.length === 0 ? false : true;
  }

  return !areSameOptions(selected, childrenData);
};

export const willDeleteSubsteps = (
  substepId: string,
  substeps: Indexed<Substep>
) => {
  const substep = getSubstep(substepId, substeps);

  if (!substep.completed) {
    return false;
  }

  const childrenData = mapChildrenToCreationDatas(substep.id, substeps).filter(
    isNotNil
  );

  const selected = substep.selectedOptions;

  if (selected === null) {
    return childrenData.length === 0 ? false : true;
  }

  return !areOptionsFromAIncludedInB(childrenData, selected);
};

export const mapChildrenToCreationDatas = (
  parentId: string,
  substeps: Indexed<Substep>
) => getChildren(parentId, substeps).map(({ creationData }) => creationData);

export const removeSubstepChildren = (
  substepId: string,
  substeps: Indexed<Substep>,
  removeRoot: boolean = false
): Indexed<Substep> => {
  const array = removeRoot
    ? Object.values(substeps).filter(({ id }) => id !== substepId)
    : Object.values(substeps);

  return array.reduce<Indexed<Substep>>(
    (acc, entry) =>
      entry.parent === substepId
        ? removeSubstepChildren(entry.id, acc)
        : { ...acc, [entry.id]: entry },
    {}
  );
}; //filter(entry => entry.parent !== substepId);

export const getSubstepsForProject = (
  projectId: string,
  substeps: Indexed<Substep>
): Indexed<Substep> =>
  Object.values(substeps).reduce((acc, substep) => {
    if (substep.projectId !== projectId) {
      return acc;
    }
    return {
      ...acc,
      [substep.id]: substep
    };
  }, {});

export const getNewSubstepId = (substeps: SubstepState) => cuid();

export const getSubstep = (
  substepId: string | null,
  substeps: Indexed<Substep>
) => substeps[substepId ?? ""];

export const substepsReducer = (
  state: SubstepState = initialState,
  action: AnyMainAction
): SubstepState => {
  switch (action.type) {
    case getType(deleteSubpart):
      const subpartId = getSubstepClosestSubpart(action.payload.id, state.byId)
        .id;
      const withoutSubsteps = removeEntries(
        getSubpartSubsteps(action.payload.id, state.byId).map(
          entry => entry.id
        ),
        state.byId
      );

      const withSubpartNodeNotCompleted = editEntry(
        subpartId,
        entry => ({
          ...entry,
          completed: false
        }),
        withoutSubsteps
      );

      return {
        ...state,
        byId: withSubpartNodeNotCompleted ?? state.byId
      };

    /*case getType(fetchProjectsSuccess):
      const serverSubsteps = collectionToIndexed(
        reconstructTree(
          action.payload.subpartsData,
          action.payload.projectsData,
          Object.values(state.byId)
        )
      );

      return {
        ...state,
        byId: { ...state.byId, ...serverSubsteps }
      };*/

    case getType(fetchProjectSuccess):
      const serverSubsteps = collectionToIndexed(
        reconstructTree(
          action.payload.subpartsData,
          action.payload.projectsData,
          Object.values(state.byId)
        )
      );

      return {
        ...state,
        byId: { ...serverSubsteps }
      };

    case getType(addProjectRequest):
      const rootID = `${action.payload.id}-root`;
      const opts = action.payload.options; /* TODO */
      const firstChildren: Substep[] = getNewSubstepConfigurations(
        opts,
        1,
        action.payload.steps
      ).map((conf, index) => ({
        id: `${action.payload.id}-root-${index}`,
        name: conf.name,
        creationData: conf.creationData,
        stepLevel: 1,
        projectId: action.payload.id,
        parent: rootID,
        substeps: [],
        backendProjectId: null,
        selectedOptions: null,
        completed: false
      }));

      const root: Substep = {
        id: `${action.payload.id}-root`,
        stepLevel: 0,
        name: action.payload.label,
        creationData: null,
        backendProjectId: null,
        parent: null,
        projectId: action.payload.id,
        selectedOptions: action.payload.options,
        completed: true
      };

      return {
        ...state,
        byId: {
          ...state.byId,
          ...collectionToIndexed([...firstChildren, root])
        }
      };

    case getType(duplicateTemplateSuccess):
      const projectParts:ServerProjectPart[] = action.payload.templateParts.map(
        part => {
          return {
            '@id': part['@id'],
            project: action.payload.projectId,
            codePI: 0,
            labelPI: part.optionPI.labelShort ?? part.optionPI.label,
            optionPI: part.optionPI,
            optionTP: part.optionTP,
            optionNS: part.optionNS,
            optionES: part.optionES,
            optionTE: part.optionTE,
            optionMA: part.optionMA,
            optionPR: part.optionPR,
            optionPO: part.optionPO,
            isDone: false,
            paramArea: {
              total: 0,
              in: [],
              out: [],
              unitSystem: ''
            },
            area: 0,
            products: [],
            product: null
          }
        }
      );

      const project:ServerProject[] = [{
        '@id': action.payload.projectId,
        label: action.payload.projectLabel,
        dateCreation: formatDate(new Date()),
        dateEdition: null,
        dateLastUpdate: formatDate(new Date()),
        isTemplate: false,
        isArchived: false,
        user: null,
        pdf: null,
        cartUrl: null,
        isDone: false,
        isEdited: false,
        hash: '',
        frontendId: action.payload.projectId,
        projectParts: []
      }];

      const newProjectSubsteps = collectionToIndexed(
        addProjectToTree(
          projectParts,
          project,
          Object.values(state.byId),
          action.payload.projectId
        )
      );

      return {
        ...state,
        byId: { ...state.byId, ...newProjectSubsteps }
      };

    case getType(duplicateProjectSuccess):
      return state; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!todo

    case getType(saveProjectSuccess):
      return {
        ...state,
        byId: collectionToIndexed([
          ...Object.values(state.byId),
          ...Object.values(state.byId)
            .filter(sub => sub.projectId === action.payload.id)
            .map(sub => ({
              ...sub,
              backendProjectId: action.payload.data["@id"]
            }))
        ])
      };

    case getType(saveProjectError):
      return {
        ...state,
        byId: collectionToIndexed([
          ...Object.values(state.byId),
          ...Object.values(state.byId)
            .filter(sub => sub.projectId === action.payload.id)
            .map(sub => ({
              ...sub,
              backendProjectId: action.payload.projectBackendId || null
            }))
        ])
      };

    case getType(setSubstepOptions):
      return {
        ...state,
        byId:
          editEntry(
            action.payload.substepId,
            entry => ({
              ...entry,
              selectedOptions: action.payload.options
            }),
            state.byId
          ) || state.byId
      };

    case getType(validateSubstep):
      const parentSubstep = getSubstep(action.payload.substepId, state.byId);

      const { steps } = action.payload;

      const currentChildrenCreationDatas = mapChildrenToCreationDatas(
        parentSubstep.id,
        state.byId
      ).filter(isNotNil);

      const selectedCreationDatas = parentSubstep.selectedOptions ?? [];

      const newSubstepsCreationData = getOptionsFromBNotIncludedInA(
        currentChildrenCreationDatas,
        selectedCreationDatas
      );

      const newSubstepConfigurations = getNewSubstepConfigurations(
        newSubstepsCreationData,
        parentSubstep.stepLevel,
        steps
      );

      const creationDataToDelete = getOptionsFromANotIncludedInB(
        currentChildrenCreationDatas,
        selectedCreationDatas
      );

      const currentChildren = getChildren(parentSubstep.id, state.byId);

      const substepsToDelete = creationDataToDelete
        .map(val =>
          currentChildren.find(
            curr => (curr.creationData?.id ?? false) === val.id
          )
        )
        .filter(isNotNil);

      const withRemovedSubsteps = substepsToDelete.reduce(
        (acc, val) => removeSubstepChildren(val.id, acc, true),
        state.byId
      );

      const newSubsteps = newSubstepConfigurations.map(
        ({ name, creationData }): Substep => ({
          id: getNewSubstepId(state),
          name,
          creationData,
          projectId: parentSubstep.projectId,
          stepLevel: parentSubstep.stepLevel + 1,
          parent: parentSubstep.id,
          backendProjectId: null,
          selectedOptions: null,
          completed: isLastStep(parentSubstep.stepLevel + 1, steps)
            ? true
            : false
        })
      );

      if (parentSubstep.stepLevel === 0) {
        currentChildrenCreationDatas.map(data => {
          if (parentSubstep && parentSubstep.selectedOptions) {
            data.label = parentSubstep.selectedOptions.find(option => option.codePI === data.codePI)?.label ?? data.label;
          }
        })
      }

      Object.values(withRemovedSubsteps)
        .filter(item => item.stepLevel === 11)
        .map(data => {
          if (data.parent && withRemovedSubsteps[data.parent] && withRemovedSubsteps[data.parent].selectedOptions && withRemovedSubsteps[data.parent].selectedOptions?.length) {
            let options = withRemovedSubsteps[data.parent].selectedOptions;
            if (options) withRemovedSubsteps[data.id].creationData = options[0];
          }
        })

      return {
        ...state,
        byId: {
          ...appendEntries(newSubsteps, withRemovedSubsteps),
          ...editEntry(
            parentSubstep.id,
            entry => ({
              ...entry,
              completed: true,
              substeps: newSubsteps.map(proj => proj.id)
            }),
            withRemovedSubsteps
          )
        }
      };

    default:
      return state;
  }
};
