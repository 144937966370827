import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import { RecapResponse } from "../model/projects/projects-model";
import { recapProjectService } from "../model/projects/projects-service";
import { getStepList, StepList } from "../model/steps/steps-model";
import { validateSubstep } from "../model/substeps/substeps-actions";
import {
  getProjectSubstepDatasForRecap,
  makeUniqueProjectSubstepsSelector
} from "../model/substeps/substeps-model";
import { convertDataToRecap } from "../model/substeps/substeps-service";
import style from "../styles/modules/project-recap.module.scss";
import { usePromise } from "../utils/react-utils";
import { PropsType } from "../utils/ts-utils";
import { DeleteSubpart } from "./delete-subpart";
import { SubstepEntryComponent } from "./substeps-list";
import { Tra } from "../components/tra";

type OwnProps = RouteComponentProps<{ projectId: string }>;

const mapStateToProps = (state: MainState) => {
  const selector = makeUniqueProjectSelector();
  const substepsSelector = makeUniqueProjectSubstepsSelector();

  return (state: MainState, props: OwnProps) => ({
    project: selector(state, props.match.params.projectId),
    steps: getStepList(state.steps),
    substeps: substepsSelector(state, props.match.params.projectId)
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  validate: (substepId: string, steps: StepList) => {
    dispatch(validateSubstep(substepId, steps));
  }
});
export const Sup: SubstepEntryComponent = ({ substep, substeps, steps }) => {
  return (
    <div>
      {substep.stepLevel > 0 ? substep.name : null}{" "}
      {substep.stepLevel === 1 ? (
        <div>
          <Link to={{ pathname: "/items-list" }} target="_blank">
            Modifier
          </Link>{" "}
        </div>
      ) : (
        <div></div>
      )}{" "}
    </div>
  );
};

export const Component: FunctionComponent<PropsType<
  OwnProps,
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps
>> = ({ match, project, steps, history, substeps, validate }) => {
  const [recap] = usePromise(
    () => {
      if (!project) {
        console.error("project not found");
        return Promise.resolve([]);
      }
      let dataToSentForRecap = convertDataToRecap(
        getProjectSubstepDatasForRecap(
          project && project.id,
          substeps,
          steps !== null ? steps : []
        )
      );

      return recapProjectService(dataToSentForRecap).then(
        (res: RecapResponse) => {
          return res;
        }
      );
    },
    [],
    [substeps]
  ); // eslint-disable-line react-hooks/exhaustive-deps
  // FIXME

  const editSubstep = (substepMesureId: string) => {
    history.push(`/projects/${project.id}/substeps/${substepMesureId}`);
  };

  if (!project) {
    history.push("/main");
    return null;
  }
  const countSubpart = getProjectSubstepDatasForRecap(
    project && project.id,
    substeps,
    steps !== null ? steps : []
  ).length;

  return (
    <div>
      <h2 className={style["projet-recap__title"]}>
        <Tra code="title-overview-list" />
      </h2>
      {recap.map((rec: any, idx: any) => (
        <div key={` rec${idx}`}>
          <h5>{rec.label}</h5>
          <div className={style["projet-recap__container"]}>
            {rec.children.map((child: any, idx: any) => (
              <div key={`child${idx}`}>
                <div className={style["projet-recap__grid-container"]}>
                  <div className={style["projet-recap__substep-header"]}>
                    {" "}
                    <span className={style["projet-recap__child-label"]}>
                      {child.label}
                    </span>
                    <label className={style["projet-recap__substep-area"]}>
                      {" "}
                      {child.area} m²{" "}
                    </label>
                    <div className={style["projet-recap__btn"]}>
                      <div className={style["projet-recap__btn-modif"]}>
                        {" "}
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={e => editSubstep(child.id)}
                        >
                          <Tra code="btn-overview-subproject-update" />
                        </button>
                      </div>
                      &nbsp;
                      {countSubpart > 1 ? (
                        <DeleteSubpart
                          buttonComponent={({ onClick }) => (
                            <button
                              onClick={onClick}
                              className={`${style["projet-recap__btn-suppr"]} btn btn-danger`}
                            >
                              <Tra code="btn-overview-subproject-delete" />
                            </button>
                          )}
                          substepId={child.id}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div id="app" className={style[""]}>
                    <div
                      className={`${style["projet-recap__grid"]} ${style["projet-recap__grid-header"]}`}
                    >
                      <label></label>
                      <label>
                        <Tra code="lbl-overview-product" />
                      </label>
                      <label>
                        <Tra code="lbl-overview-quantity" />
                      </label>
                    </div>

                    {child.products.map((product: any, idx: any) => (
                      <div
                        key={`product${idx}`}
                        className={style["projet-recap__grid"]}
                      >
                        <div>
                          <img
                            src={product.picture}
                            className={style["projet-recap__grid-item-picture"]}
                            alt=""
                          />
                        </div>
                        <label>{product.label}</label>
                        <div
                          className={style["projet-recap__grid-item-quantity"]}
                        >
                          {product.quantity}
                        </div>
                        <div className={style["projet-recap__grid-item-link"]}>
                          {product.url ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={product.url}
                            >
                              <Tra code="lbl-overview-link-article" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const ProjectRecap = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
