import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import { fetchItemsList } from "../model/projects/projects-service";
import style from "../styles/modules/project-items.module.scss";
import { usePromise } from "../utils/react-utils";
import { PropsTypeNoDispatch } from "../utils/ts-utils";
import { Tra } from "../components/tra";

type OwnProps = RouteComponentProps<{
  projectId?: string;
}>;

export const mapStateToProps = () => {
  const selector = makeUniqueProjectSelector();
  return (state: MainState, props: OwnProps) => ({
    project: props.match.params.projectId
      ? selector(state, props.match.params.projectId)
      : null
  });
};

export const Component: FunctionComponent<PropsTypeNoDispatch<
  OwnProps,
  ReturnType<typeof mapStateToProps>
>> = ({ match, project }) => {

  const [items] = usePromise(
    () => (project ? fetchItemsList(project) : Promise.resolve([])),
    [],
    [project]
  );

  const showPicture = (picture: any) => {
    if (picture) {
      return <img src={picture} className={style["ui-item-picture"]} alt="" />;
    }
    return "";
  };

  if (!project) {
    console.log("project not found");
    return <Redirect to="/main" />;
  }

  const displayAlert = (alert: any) => {
    if (alert) {
      return <p className={style["ui-item-alert"]}>{alert}</p>;
    }
    return "";
  };

  return (
    <div>
      <h2 className={style["ui-item-table-title"]}>
        <Tra code="title-cart-list" />
      </h2>
      <Table className={style["ui-item-table"]} responsive>
        <thead>
          <tr>
            <th></th>
            <th>
              <Tra code="lbl-cart-product" />
            </th>
            <th>
              <Tra code="lbl-cart-quantity" />
            </th>
            <th>
              <Tra code="lbl-cart-reference" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item["@id"]} className={style["ui-item-table-tr"]}>
              <td>{showPicture(item.article.picture)}</td>
              <td data-label="Produit">
                {item.article.label}
                {displayAlert(item.cartAlert)}
                {displayAlert(item.availabilityAlert)}
              </td>
              <td data-label="Quantité">{item.quantity}</td>
              <td data-label="Référence">{item.article.reference}</td>
              <td className={style["ui-item-table-td"]}>
                {item.article.url ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.article.url}
                  >
                    <Tra code="lbl-cart-link-article" />
                  </a>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const ProjectItemsList = connect(mapStateToProps)(Component);
