import { useEffect, useRef, useState } from "react";

/**
 * https://juliangaramendy.dev/use-promise-subscription/
 * custom hook to manage data you retrieve from promises
 */

export const usePromise = <T>(
  promiseFunction: () => Promise<T>,
  defaultValue: T,
  dependencies: any[]
) => {
  const [state, setState] = useState<{
    value: T;
    error: any;
    isPending: boolean;
  }>({
    value: defaultValue,
    error: null,
    isPending: true
  });

  useEffect(() => {
    const promise =
      typeof promiseFunction === "function"
        ? promiseFunction()
        : promiseFunction;

    let isSubscribed = true;
    promise
      .then(value =>
        isSubscribed ? setState({ value, error: null, isPending: false }) : null
      )
      .catch(error =>
        isSubscribed
          ? setState({ value: defaultValue, error: error, isPending: false })
          : null
      );

    return () => {
      isSubscribed = false;
    };
    // suppression du linting car la dependance sur default value n'est pas une bonne idée mais attention aux dépendances !!!!
    // eslint-disable-next-line
  }, dependencies);

  const { value, error, isPending } = state;

  type ReturnTuple = [typeof value, typeof error, typeof isPending];

  const toReturn: ReturnTuple = [value, error, isPending];

  return toReturn;
};

export const useComputed = <T>(
  fn: () => T,
  defaultValue: T,
  dependencies: any[]
) => {
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    setState(fn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return state;
};

// type ListType<T> = FunctionComponent<{ list: T[], component: Component }>;

// export function List<T> ({ list, EntryComponent }: { list: T[], EntryComponent: Component<{ entry: T}> }) { <ul>
//   {
//     list ? list.map(entry => <EntryComponent entry={entry}/>) : null
//   }
// </ul> }
export const useTraceUpdate = (props: { [index: string]: any }) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce<{ [index: string]: any }>(
      (ps, [k, v]) => {
        if (prev.current[k] !== v) {
          ps[k] = [prev.current[k], v];
        }
        return ps;
      },
      {}
    );
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
};
