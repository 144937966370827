import { History } from "history";
import { Middleware } from "redux";
import { AUTH_REGISTER_SUCCESS } from "../model/authentication/authentication-actions";
import { AnyMainAction } from "../model/main-actions";
import { MainState } from "../model/main-model";
import {
  NAVIGATE_TO,
  NAVIGATE_TO_CURRENT_SUBSTEP
} from "../model/nav/nav-actions";
import {
  ADD_PROJECT_REQUEST,
  SAVE_PROJECT_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECTS_SUCCESS
} from "../model/projects/projects-actions";
import {
  getCurrentSubstep,
  getCurrentSubstepForProject,
  getRootSubstep
} from "../model/substeps/substeps-model";
import { getProjectLink } from "../utils/router-utils";
// import { NAVIGATE_TO_CURRENT_SUBSTEP } from "../model/nav/nav-actions";

export const navigateOnAction = (
  getRouterHistory: () => History
): Middleware<{}, MainState> => store => next => (action: AnyMainAction) => {
  next(action);
  // if (action.type === FINISH_PROJECT_SUCCESS) {
  //   window.setTimeout(() => getRouterHistory().push(`/`), 1);
  // }

  if (action.type === SAVE_PROJECT_SUCCESS && action.payload.shouldRedirect) {
    window.setTimeout(() => {
      if (action.payload.pdfDownloadURL) {
        window.open(action.payload.pdfDownloadURL, "_blank");
      }
      getRouterHistory().push(
        action.payload.data.isEdited
          ? `/items-list/${action.payload.id}`
          : "/main"
      );
    }, 1);
  }

  if (action.type === FETCH_PROJECTS_SUCCESS) {
    if (action.payload.backendIdRedirect) {
      const proj = Object.values(store.getState().projects.byId).find(
        proj => proj.backendId === action.payload.backendIdRedirect
      );

      if (proj) {
        getRouterHistory().push(getProjectLink(proj));
      }
    }
  }
  if (action.type === ADD_PROJECT_REQUEST) {
    window.setTimeout(
      () => getRouterHistory().push(`/projects/${action.payload.id}`),
      1
    );
  }

  if (action.type === FETCH_PROJECT_SUCCESS) {
    window.setTimeout(
      () => getRouterHistory().push(
        action.payload.projectsData[0].isEdited ?
          `/items-list/${action.payload.projectsData[0].frontendId}` :
          `/projects/${action.payload.projectsData[0].frontendId}`
      ),
      1000
    );
  }

  if (action.type === NAVIGATE_TO) {
    window.setTimeout(() => getRouterHistory().push(action.payload.path), 1);
  }

  /*if (action.type === AUTH_REGISTER_SUCCESS) {
    window.setTimeout(() => getRouterHistory().push(`/login`), 1);
  }*/

  if (action.type === NAVIGATE_TO_CURRENT_SUBSTEP) {
    const substeps = store.getState().substeps.byId;

    const substep =
      getCurrentSubstep(action.payload.substepId, substeps) ||
      getCurrentSubstepForProject(action.payload.projectId, substeps) ||
      getRootSubstep(action.payload.projectId, substeps);
    const projectId = substep.projectId;
    getRouterHistory().push(`/projects/${projectId}/substeps/${substep.id}`);
  }
};
