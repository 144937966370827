import React, { FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { BackBtn } from "../../components/back-btn";
import {
  authDeleteAccountRequest,
  authEditInfosRequest,
  authLanguagesRequest,
  authPasswordEditRequest,
  authPreferenceEditRequest,
  authUnitSystemRequest,
  authCountriesRequest,
  authDepartmentsRequest,
  authProfessionsRequest
} from "../../model/authentication/authentication-actions";
import {
  EntityLanguage,
  EntityUnitSystem,
  EntityCountry,
  EntityDepartment,
  EntityProfession
} from "../../model/authentication/authentication-model";
import { MainState } from "../../model/main-model";
import styles from "../../styles/modules/edit-profile.module.scss";
import {Alert, Form} from "react-bootstrap";
import {Tra} from "../../components/tra";
import {TranslationContext, useT} from "../i18n-container";
import {ApiError} from "../../utils/server-utils";
import {I18NState} from "../../model/i18n/i18n-model";

type OwnProps = RouteComponentProps;

export type EditProfileInfoState = {
  firstName: string;
  lastName: string;
  email: string;
  shopEmails: string[];
  country: string | null;
  department: string | null;
  profession: string| null;
  phoneNumber: string;
};

export type EditProfilePasswordState = {
  oldPassword: string;
  newPassword: string;
  passwordConfirm: string;
};

export type EditProfilePreferencesState = {
  language: string;
  unitSystem: string;
};

export type EditDeleteAccountState = {
  deletionConfirm: boolean;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getLanguages: () => {
    return dispatch(authLanguagesRequest());
  },
  getUnitSystems: () => {
    return dispatch(authUnitSystemRequest());
  },
  updatePreferences: (data: EditProfilePreferencesState) => {
    return dispatch(authPreferenceEditRequest(data));
  },
  editPassword: (data: EditProfilePasswordState) => {
    return dispatch(authPasswordEditRequest(data));
  },
  editInfos: (data: EditProfileInfoState) => {
    return dispatch(authEditInfosRequest(data));
  },
  deleteUserAccount: (data: EditDeleteAccountState) => {
    return dispatch(authDeleteAccountRequest(data, dispatch));
  },
  getCountries: () => {
    return dispatch(authCountriesRequest())
  },
  getDepartments: () => {
    return dispatch(authDepartmentsRequest())
  },
  getProfessions: () => {
    return dispatch(authProfessionsRequest())
  }
});

const mapStateToProps = (state: MainState) => ({
  languagesState: state.authentication.languages,
  unitSystemState: state.authentication.unitSystems,
  currentLastname: state.authentication.data?.user.lastname,
  currentFirstname: state.authentication.data?.user.firstname,
  currentEmail: state.authentication.data?.user.email,
  currentShopEmails: state.authentication.data?.user.shopEmails,
  currentLanguage: state.authentication.data?.user.language,
  currentUnitSystem: state.authentication.data?.user.unitSystem,
  currentCountry: state.authentication.data?.user.country,
  currentDepartment: state.authentication.data?.user.department,
  currentProfession: state.authentication.data?.user.profession,
  currentPhoneNumber: state.authentication.data?.user.phoneNumber,
  dataErrors: state.authentication.errors?.data,
  prefErrors: state.authentication.errors?.prefs,
  prefResult: state.authentication.result?.prefs,
  resetErrors: state.authentication.errors?.reset,
  dataResult: state.authentication.result?.data,
  resetResult: state.authentication.result?.reset,
  deleteErrors: state.authentication.errors?.delete,
  countryState: [{id: 0, label: ''}, ...state.authentication.countries],
  departmentState: [{id: 0, label: ''}, ...state.authentication.departments],
  professionState: [{id: 0, label: ''}, ...state.authentication.professions],
  defaultCountry: state.authentication.countries.filter(item => item.isDefault)[0]['@id']
});

type PropsType = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Component = ({
  deleteUserAccount,
  currentLanguage,
  getUnitSystems,
  getLanguages,
  getCountries,
  getDepartments,
  getProfessions,
  languagesState,
  currentUnitSystem,
  unitSystemState,
  countryState,
  departmentState,
  professionState,
  updatePreferences,
  editPassword,
  editInfos,
  dataErrors,
  dataResult,
  prefErrors,
  prefResult,
  resetResult,
  resetErrors,
  deleteErrors,
  currentLastname,
  currentFirstname,
  currentEmail,
  currentShopEmails,
  currentPhoneNumber,
  currentCountry,
  currentDepartment,
  currentProfession
}: PropsType) => {
  useEffect(() => {
    getLanguages();
    getUnitSystems();
    getCountries();
    getDepartments();
    getProfessions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // FIXME

  const languages =
    languagesState !== undefined ? languagesState["hydra:member"] : [];
  const unitSystems: EntityUnitSystem[] =
    unitSystemState !== undefined ? unitSystemState : [];
  const countries : EntityCountry[] =
    countryState !== undefined ? countryState : [];
  const departments : EntityDepartment[] =
    departmentState !== undefined ? departmentState : [];
  const professions : EntityProfession[] =
    professionState !== undefined ? professionState : [];
  const defaultCountry = countries.filter(item => item.isDefault)
  const [formEditInfoState, updateFormEditInfoState] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    shopEmails: any;
    phoneNumber: any;
    country: string | null;
    defaultCountry: EntityCountry | null;
    department: string | null;
    profession: string | null;
  }>({
    firstName: currentFirstname ? currentFirstname : "",
    lastName: currentLastname ? currentLastname : "",
    email: currentEmail ? currentEmail : "",
    shopEmails: currentShopEmails ? currentShopEmails : [{ value: "" }],
    phoneNumber: currentPhoneNumber ?? "",
    country: currentCountry ?? null,
    defaultCountry: defaultCountry[0] ?? null,
    department: currentDepartment ?? null,
    profession : currentProfession ??null
  });
  const [formEditPasswordState, updateFormEditPasswordState] = useState<
    EditProfilePasswordState
  >({ oldPassword: "", newPassword: "", passwordConfirm: "" });

  const [formEditPreferencesState, updateformEditPreferenceState] = useState<
    EditProfilePreferencesState
  >({
    language: currentLanguage ? currentLanguage : "",
    unitSystem:
      currentUnitSystem ??
      (unitSystems.length !== 0 ? unitSystems[0]["@id"] : "")
  });
  const [email, setEmail] = useState();
  const [deletionConfirm, setdeleteAccount] = useState(false);
  const [isEmailkeyup, setEmailkeyup] = useState(true);

  const handleEditInfoAddEmail = () => {
    const values = [...formEditInfoState.shopEmails];
    values.push({ value: "" });
    updateFormEditInfoState({ ...formEditInfoState, shopEmails: values });
  };

  const handleFormEditInfo = (e: FormEvent) => {
    e.preventDefault();
    const editProfileInfoData: EditProfileInfoState = {
      firstName: "",
      lastName: "",
      email: "",
      shopEmails: [],
      country: null,
      department: null,
      profession: null,
      phoneNumber: ""
    };
    editProfileInfoData.firstName = formEditInfoState.firstName;
    editProfileInfoData.lastName = formEditInfoState.lastName;
    editProfileInfoData.email = formEditInfoState.email;
    editProfileInfoData.shopEmails = formEditInfoState.shopEmails;
    editProfileInfoData.phoneNumber = formEditInfoState.phoneNumber;
    editProfileInfoData.country = formEditInfoState.country === '' ? null : formEditInfoState.country;
    editProfileInfoData.department = formEditInfoState.department === '' ? null : formEditInfoState.department;
    editProfileInfoData.profession = formEditInfoState.profession === '' ? null : formEditInfoState.profession;
    editInfos(editProfileInfoData);
  };

  const handleFormEditPreferences = (e: FormEvent) => {
    e.preventDefault();
    const editProfilePreferencesData: EditProfilePreferencesState = {
      language: "",
      unitSystem: ""
    };
    editProfilePreferencesData.language = formEditPreferencesState.language;
    editProfilePreferencesData.unitSystem = formEditPreferencesState.unitSystem;
    updatePreferences(editProfilePreferencesData);
  };

  const handleFormDeleteAccount = (e: FormEvent) => {
    e.preventDefault();
    const editDeleteAccountState: EditDeleteAccountState = {
      deletionConfirm: false
    };
    editDeleteAccountState.deletionConfirm = deletionConfirm;
    deleteUserAccount(editDeleteAccountState);
  };
  const handleInputDeleteAccount = (e: any) => {
    const checked = e.target.checked;
    setdeleteAccount(checked);
  };

  const handleInputEditPrefer = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    updateformEditPreferenceState({
      ...formEditPreferencesState,
      [name]: value
    });
  };

  const handleFormEditPassword = (e: FormEvent) => {
    e.preventDefault();
    const editProfilePasswordData: EditProfilePasswordState = {
      oldPassword: "",
      newPassword: "",
      passwordConfirm: ""
    };
    editProfilePasswordData.oldPassword = formEditPasswordState.oldPassword;
    editProfilePasswordData.newPassword = formEditPasswordState.newPassword;
    editProfilePasswordData.passwordConfirm =
      formEditPasswordState.passwordConfirm;
    editPassword(editProfilePasswordData);
  };

  const handleInputEditPassword = (e: any) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    updateFormEditPasswordState({ ...formEditPasswordState, [name]: value });
  };

  const handleInputEditInfo = (e: any) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'country') {
      updateFormEditInfoState({ ...formEditInfoState, [name]: value, ['department']: '' });
    } else {
      updateFormEditInfoState({ ...formEditInfoState, [name]: value });
    }
  };

  const handleInputEditEmail = (event: any) => {
    event.preventDefault();
    const value = event.target.value;
    setEmail(value);
    formEditInfoState.email = value;
  };

  const handleInputEditEmailMultiple = (i: any, event: any) => {
    const values = [...formEditInfoState.shopEmails];
    values[i].value = event.target.value;
    updateFormEditInfoState({ ...formEditInfoState, shopEmails: values });
    if (values[i].value) {
      setEmailkeyup(false);
    } else {
      setEmailkeyup(true);
    }
  };

  const { t } = useT();
  let sortedCountries = countries.sort((a, b) => {
    if (!a.id) return -1;
    if (!b.id) return 1;
    if (a.isDefault) return -1;
    if (b.isDefault) return 1;
    if (a.label > b.label) {
      return 1;
    }
    if (a.label < b.label) {
      return -1;
    }
    return 0;
  })

  return (
    <React.Fragment>
      <TranslationContext.Consumer>
        {({ texts }: I18NState) => (
          <div className="p-5">
            <div className="mb-2">
              <BackBtn />
            </div>
            <h1 className="mb-5">{t('title-account-management')}</h1>
            <div className={`${styles["ui-profile-edit-wrapper"]}`}>
              <div className={`${styles["ui-profile-edit-reset-pwd"]}`}>
                <form onSubmit={handleFormEditPassword}>
                  <h3>{t('title-account-password')}</h3>
                  {handleResult(resetResult)}
                  {/* {(error!==undefined) && error.message ? (
                        <p className="ui-auth-error">{error.message} </p>
                      ) : (
                        ""
                      )} */}
                  <label className={styles.blue_label}>{t('lbl-form-password-current')}</label>
                  {handleError(resetErrors, "oldPassword")}
                  <input
                    type="password"
                    name="oldPassword"
                    onChange={handleInputEditPassword}
                  />
                  <label className={styles.blue_label}>{t('lbl-form-password-new')}</label>
                  {handleError(resetErrors, "newPassword")}
                  <input
                    type="password"
                    name="newPassword"
                    onChange={handleInputEditPassword}
                  />
                  <label className={styles.blue_label}>
                    {t('lbl-form-password-confirm')}
                  </label>
                  {handleError(resetErrors, "passwordConfirm")}
                  <input
                    type="password"
                    name="passwordConfirm"
                    onChange={handleInputEditPassword}
                  />
                  <div className={`${styles["button_container"]}`}>
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={t('btn-form-password')}
                    />
                  </div>
                </form>
              </div>
              <div className={`${styles["ui-profile-edit-info"]}`}>
                <form onSubmit={handleFormEditInfo}>
                  <h3>{t('title-account-data')}</h3>
                  {handleResult(dataResult)}
                  <div className="edit-form-info">
                    <div className="d-xl-flex d-lg-flex d-md-flex">
                      <div className="mr-3">
                        <label className={styles.blue_label}>{t('lbl-form-lastname')}</label>
                        {handleError(dataErrors, "lastName")}
                        <input
                          type="text"
                          name="lastName"
                          onChange={handleInputEditInfo}
                          value={formEditInfoState.lastName}
                        />
                      </div>
                      <div className="mr-3">
                        <label className={styles.blue_label}>{t('lbl-form-firstname')}</label>
                        {handleError(dataErrors, "firstName")}
                        <input
                          type="text"
                          name="firstName"
                          onChange={handleInputEditInfo}
                          value={formEditInfoState.firstName}
                        />
                      </div>
                      <div>
                        <label className={styles.blue_label}>{t('lbl-form-phoneNumber')}</label>
                        {handleError(dataErrors, "phoneNumber")}
                        <input
                          type="text"
                          name="phoneNumber"
                          placeholder={
                            texts["lbl-form-placeholder-phoneNumber"]
                          }
                          onChange={handleInputEditInfo}
                          value={formEditInfoState.phoneNumber}
                        />
                      </div>
                    </div>
                    <div className="d-xl-flex d-lg-flex d-md-flex">
                      <div className="mr-3">
                        <label className={styles.blue_label}>{t('lbl-form-country')}</label>
                        <select
                          name="country"
                          id="country"
                          onChange={handleInputEditInfo}
                        >
                          {sortedCountries.map((country: EntityCountry, idx: any) => (
                            <option value={country["@id"]} key={`${country}-${idx}`} selected={country['@id'] === formEditInfoState.country}>
                              {country.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      { (formEditInfoState.country === (formEditInfoState.defaultCountry ? formEditInfoState.defaultCountry['@id'] : null)) ? (<div className="mr-3">
                        <label className={styles.blue_label}>{t('lbl-form-department')}</label>
                        <select
                          name="department"
                          id="department"
                          onChange={handleInputEditInfo}
                        >
                          {departments.map((department: EntityDepartment, idx: any) => (
                            <option value={department["@id"]} key={`${department}-${idx}`} selected={department['@id'] === formEditInfoState.department}>
                              {department.label}
                            </option>
                          ))}
                        </select>
                      </div>) : ''}
                      <div>
                        <label className={styles.blue_label}>{t('lbl-form-profession')}</label>
                        <select
                          name="profession"
                          id="profession"
                          onChange={handleInputEditInfo}
                        >
                          {professions.map((profession: EntityProfession, idx: any) => (
                            <option value={profession["@id"]} key={`${profession}-${idx}`} selected={profession['@id'] === formEditInfoState.profession}>
                              {profession.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <p>
                    {t('text-form-email-alt')}
                  </p>
                  <div className={`${styles["edit-form-info-groupe-mail"]}`}>
                    <div>
                      <label className={styles.blue_label}>{t('lbl-form-email')}</label>
                      {handleError(dataErrors, "email")}
                      <input
                        className="mb-2"
                        type="text"
                        name="email"
                        onChange={handleInputEditEmail}
                        value={formEditInfoState.email}
                      />
                    </div>
                    {formEditInfoState.shopEmails.map((field: any, idx: any) => {
                      return (
                        <div
                          className="d-flex mt-3 edit-form-info-groupe-email-add-input"
                          key={`${field}-${idx}`}
                        >
                          <div className="mr-2">
                            <button
                              type="button"
                              className={`${styles["plus_button"]}`}
                              name="addInfo"
                              onClick={handleEditInfoAddEmail}
                              disabled={isEmailkeyup}
                            ></button>
                          </div>

                          <div>
                            <input
                              type="text"
                              name={`email-${idx}`}
                              onChange={e => handleInputEditEmailMultiple(idx, e)}
                              value={field.value}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={`${styles["button_container"]}`}>
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={t('btn-form-save')}
                    />
                  </div>
                </form>
              </div>
              <div className="ui-profile-edit-prefer">
                <form onSubmit={handleFormEditPreferences}>
                  <h3>{t('title-account-param')}</h3>
                  {handleResult(prefResult)}
                  {handleError(prefErrors, "language")}
                  <label className={styles.blue_label}>{t('lbl-form-account-language')}</label>
                  <select
                    name="language"
                    id="language"
                    value={formEditPreferencesState.language}
                    onChange={handleInputEditPrefer}
                  >
                    {languages.map((lang: EntityLanguage, idx: any) => (
                      <option value={lang["@id"]} key={`${lang}-${idx}`}>
                        {lang.code}
                      </option>
                    ))}
                  </select>

                  <label className={styles.blue_label}>{t('lbl-form-account-system')}</label>
                  <div className="ui-unit-choice d-flex">
                    {unitSystems.map((unitSys: EntityUnitSystem, idx: number) => (
                      <div
                        className={`${styles["input-radio_container"]}`}
                        key={`${unitSys}-${idx}`}
                      >
                        {idx === 0 ? "" : " "}
                        <Form.Check
                          custom
                          type="radio"
                          id={unitSys["@id"]}
                          value={unitSys["@id"]}
                          label={unitSys.label}
                          name="unitSystem"
                          checked={
                            formEditPreferencesState.unitSystem === unitSys["@id"]
                          }
                          onChange={handleInputEditPrefer}
                        />
                      </div>
                    ))}
                    {/* <div>
                    <input
                      type="radio"
                      name="unitSystem"
                      value="metrique"
                      checked={formEditPreferencesState.unitSystem === "metrique"}
                      onChange={handleInputEditPrefer}
                    ></input>
                    <label> Métrique </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="unitSystem"
                      value="imperial"
                      checked={formEditPreferencesState.unitSystem === "imperial"}
                      onChange={handleInputEditPrefer}
                    ></input>
                    <label> Imperial </label>
                  </div> */}
                  </div>
                  <div className={`${styles["button_container"]}`}>
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={t('btn-form-param')}
                    />
                  </div>
                </form>
              </div>
              <div className="ui-profile-edit-delete">
                <form onSubmit={handleFormDeleteAccount}>
                  <h3>{t('title-account-supp')}</h3>
                  <p>
                    <Tra code="text-account-delete" />
                  </p>
                  {handleError(deleteErrors, "deletionConfirm")}
                  <div className={`${styles["delete-input_container"]}`}>
                    <label
                      className={`${styles["linecheckbox_container"]} linecheckbox_container`}
                      htmlFor="deletionConfirm"
                    >
                      <input
                        type="checkbox"
                        id="deletionConfirm"
                        name="deletionConfirm"
                        defaultChecked={deletionConfirm}
                        onChange={handleInputDeleteAccount}
                      />
                      <span className="linecheckbox_container_checkmark"> </span>
                      {t('lbl-form-account-delete')}
                    </label>
                  </div>

                  <div className={`${styles["button_container"]}`}>
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={t('btn-form-account-delete')}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </TranslationContext.Consumer>
    </React.Fragment>
  );
};

const handleError = (error: ApiError | undefined, name: string) => {
  return (
      error?.data?.violations?.map((res: any, idx: any) =>
          res.propertyPath === name ? (
              <Alert
                  variant="warning"
                  className={styles["ui-error"]}
                  key={`${res}-${idx}`}
              >
                <Tra code={res.message} />
              </Alert>
          ) : (
              ""
          )
      ) ?? ""
  );
};

const handleResult = (result: boolean | undefined, translationCode?: string) => {
  return result ? (
      <Alert variant="info">
        <Tra code={translationCode ?? 'label-form-success'} />
      </Alert>
  ) : null;
}

export const editProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
