import React, { FunctionComponent, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { ReactComponent as Trash } from "../assets/trash.svg";
import { BottomBar } from "../components/bottom-bar";
import { Tra } from "../components/tra";
import { MainState, makeUniqueProjectSelector } from "../model/main-model";
import {
  areOptionsEmpty,
  getStepList,
  isSecondToLastStep,
  StepList
} from "../model/steps/steps-model";
import { validateSubstep } from "../model/substeps/substeps-actions";
import {
  getCurrentSubstepForProject,
  getPreviousSubstep,
  getRemainingOpenSubstepsForProject,
  getRootSubstep,
  getSubstep,
  willDeleteSubsteps
} from "../model/substeps/substeps-model";
import styles from "../styles/modules/project-bottom-bar.module.scss";
import { getSubstepLink } from "../utils/router-utils";
import { PropsType } from "../utils/ts-utils";
import { DeleteSubpart } from "./delete-subpart";
import { getBottomLabel } from "../model/progressBar/progress-bar-model";

type OwnProps = RouteComponentProps<{ projectId: string; substepId: string }>;

const mapStateToProps = (state: MainState) => {
  const selector = makeUniqueProjectSelector();
  return (state: MainState, props: OwnProps) => ({
    project: selector(state, props.match.params.projectId),
    steps: getStepList(state.steps),
    substeps: state.substeps.byId,
    progress: state.progresBar.progresBarState
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  validate: (substepId: string, steps: StepList) => {
    dispatch(validateSubstep(substepId, steps));
  }
});

export const Component: FunctionComponent<PropsType<
  OwnProps,
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps
>> = ({
  match,
  project,
  substeps,
  children,
  validate,
  history,
  steps,
  progress
}) => {
  const [shown, setShown] = useState(false);

  if (!steps) {
    return null;
  }
  if (!project) {
    return null;
  }
  const substep = match.params.substepId
    ? getSubstep(match.params.substepId, substeps)
    : getCurrentSubstepForProject(project.id, substeps) ||
      getRootSubstep(project.id, substeps);

  if (!substep) {
    console.log("substep not found");
    return <Redirect to="/main" />; // TODO FIXME
  }

  const secondToLast = isSecondToLastStep(substep.stepLevel, steps);
  const hasRemaining =
    getRemainingOpenSubstepsForProject(project.id, substeps).filter(
      sub => sub.id !== substep.id
    ).length > 0;

  const previousSubstep = getPreviousSubstep(substep.id, substeps);
  const bottomLabel = getBottomLabel(substep.creationData?.type, progress);

  const getThumbnail = (stepCode: string | undefined) => {
    switch (stepCode) {
      case "MO":
        return progress.filter((p, idx) => p.code === "MO")[0]?.thumbnail;
        break;
      case "CO":
        return progress.filter((p, idx) => p.code === "CO")[0]?.thumbnail;
        break;
      default:
        return null;
    }
  };

  const thumbnail = getThumbnail(substep.creationData?.type);

  const onNext = () => {
    if (willDeleteSubsteps(substep.id, substeps) || secondToLast) {
      setShown(true);
    } else {
      validate(substep.id, steps);
    }
  };

  const modal = (
    <Modal show={shown} onHide={() => setShown(false)}>
      <Modal.Body>
        {secondToLast ? (
          <Alert variant="info">
            <Tra code="text-project-next-subproject" />
          </Alert>
        ) : (
          <Alert variant="warning">
            <Tra code="text-project-delete-next" />
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => setShown(false)}>
          <Tra code="btn-cancel" />
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            validate(substep.id, steps);
            setShown(false);
          }}
        >
          {secondToLast ? (
            <Tra code="btn-project-continue" />
          ) : (
            <Tra code="btn-project-validate-next" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (secondToLast && !hasRemaining) {
    return (
      <BottomBar>
        {modal}
        <div
          className={`${styles["bottombar_left"]} justify-content-between main-footer_right`}
        >
          <span className={"bottom-label"}>{bottomLabel}</span>
          {thumbnail ? (
            <img className={"color-thumbnail"} src={thumbnail} />
          ) : null}
        </div>
        <div
          className={`${styles["bottombar_right"]} d-flex justify-content-end`}
        >
          <DeleteSubpart
            buttonComponent={({ onClick }) => (
              <div
                onClick={onClick}
                role="button"
                className={styles.bottombar_right_delete}
              >
                <Trash />
                <Tra code="btn-footer-delete-subproject" />
              </div>
            )}
            substepId={substep.id}
          />
          {previousSubstep ? (
            <Link
              to={getSubstepLink(project.id, previousSubstep.id)}
              className="btn btn-secondary btn-bar-left"
            >
              <Tra code="btn-footer-previous" />
            </Link>
          ) : null}
          <button
            className="btn btn-primary btn-bar-right"
            disabled={areOptionsEmpty(substep.selectedOptions)}
            onClick={() => {
              return validate(substep.id, steps);
            }}
          >
            <Tra code="btn-footer-overview" />
          </button>
        </div>
      </BottomBar>
    );
  }

  return (
    <BottomBar>
      {modal}
      <div
        className={`${styles["bottombar_left"]} justify-content-start main-footer_right`}
      >
        <span className={"bottom-label"}>{bottomLabel}</span>
        {thumbnail ? (
          <img className={"color-thumbnail"} src={thumbnail} />
        ) : null}
      </div>
      <div
        className={`${styles["bottombar_right"]} d-flex justify-content-end`}
      >
        {substep.parent ? (
          <DeleteSubpart
            buttonComponent={({ onClick }) => (
              <div
                onClick={onClick}
                role="button"
                className={styles.bottombar_right_delete}
              >
                <Trash />
                <Tra code="btn-footer-delete-subproject" />
              </div>
            )}
            substepId={substep.id}
          />
        ) : null}
        {previousSubstep ? (
          <Link
            to={getSubstepLink(project.id, previousSubstep.id)}
            className="btn btn-secondary btn-bar-left"
          >
            <Tra code="btn-footer-previous" />
          </Link>
        ) : null}
        <button
          className="btn btn-primary btn-bar-right"
          disabled={areOptionsEmpty(substep.selectedOptions)}
          onClick={onNext}
        >
          {secondToLast ? (
            <Tra code="btn-footer-next-subproject" />
          ) : (
            <Tra code="btn-footer-next-step" />
          )}
        </button>
      </div>
    </BottomBar>
  );
};

export const ProjectBottomBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
