// @ts-ignore
import * as dayjsModule from "dayjs";
import "dayjs/locale/fr";
import React, {FunctionComponent, useCallback} from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReactComponent as IcoRightArrow } from "../assets/arrow-point-to-right.svg";
import { ReactComponent as IcoDuplicate } from "../assets/duplicate-icon.svg";
import { ReactComponent as IcoArchive } from "../assets/archive-icon.svg";
import { ReactComponent as IcoTemplate } from "../assets/template-icon.svg";
import { ReactComponent as IcoDelete } from "../assets/bin-ico-notofficial.svg";
import { useT } from "../containers/i18n-container";
import { SaveProject } from "../containers/save-project";
import { ProjectState } from "../model/projects/projects-model";
import styles from "../styles/modules/project-list-entry.module.scss";
import { Tra } from "./tra";
import { ReactComponent as IconProjectCurrent } from "../assets/edit-notofficial.svg";
import { ReactComponent as IcoProjectFinished } from "../assets/finished-icon.svg";
import { ReactComponent as IcoProjectArchived } from "../assets/archive-icon.svg";
import {fetchProjectRequest} from "../model/projects/projects-actions";
import {Link} from "react-router-dom";
import {getProjectLink} from "../utils/router-utils";

// @ts-ignore
const dayjs = dayjsModule.default;
dayjs.locale("fr");

interface OwnProps {
  project: ProjectState;
  state: string;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // onDuplicate: (projectID: string) =>
  //   dispatch(duplicateProjectRequest(projectID))
    loadProject: (projectId: string) =>
      dispatch(fetchProjectRequest(projectId))
});

export const Component: FunctionComponent<OwnProps &
  ReturnType<typeof mapDispatchToProps>> = ({
  project,
  loadProject,
  state
}) => {
  const { t } = useT();

  const openProject = useCallback(() => {
    if (project.backendId) {
      loadProject(project.backendId.split('/')[2]);
    }
  }, []);

  let icon;

  if (project.isArchived) {
    icon = <IcoProjectArchived/>;
  } else if (project.isEdited) {
    icon = <IcoProjectFinished className={styles["logo-inversed"]}/>;
  } else {
    icon = <IconProjectCurrent/>;
  }

  return (
    <div className={styles["project-list-entry"]}>
      <div className="d-flex">
        <div className={styles["project-svg"]}>
          {icon}
        </div>
        <div>
          <strong>
            {project.label}
          </strong>
          <div>
            <small>
              { project.isEdited ? (
                <Tra code="lbl-project-edited-on" />
              ) : (
                <Tra code="lbl-project-created-on" />
              )}
              {" "}
              {project.isEdited && project.dateEdition ?
                dayjs(project.dateEdition).format("dddd D MMMM YYYY") :
                dayjs(project.dateCreation).format("dddd D MMMM YYYY")
              }
            </small>
          </div>
        </div>
      </div>
      <div className="mt-auto mb-auto">
        <span className={styles["blue_label"]}>{t('lbl-project-state-' + state)}</span>

        <SaveProject
          projectId={project.id}
          loginModalText={t("text-login-to-delete")}
          saveModalText={t("lbl-project-delete")}
          saveButtonLabel={t("lbl-delete")}
          shouldDelete={true}
          buttonComponent={({ onClick }) => (
            <button
              type="button"
              title={t("lbl-delete")}
              className="btn btn-link"
              onClick={onClick}
            >
              <IcoDelete></IcoDelete>
            </button>
          )}
        />

        { (project.isEdited || project.isTemplate) ? (
          <SaveProject
            projectId={project.id}
            loginModalText={t("text-login-to-duplicate")}
            saveModalText={t("lbl-project-duplicate")}
            saveButtonLabel={t("lbl-duplicate")}
            shouldDuplicate={true}
            buttonComponent={({ onClick }) => (
              <button
                type="button"
                title={t("lbl-duplicate")}
                className="btn btn-link"
                onClick={onClick}
              >
                <IcoDuplicate></IcoDuplicate>
              </button>
            )}
          />
        ) : null }

        { !project.isTemplate ? (
          <SaveProject
            projectId={project.id}
            loginModalText={t("text-login-to-set-as-template")}
            shouldSetAsTemplate={true}
            saveButtonLabel={t("lbl-set-as-template")}
            saveModalText={t("lbl-project-set-as-template")}
            buttonComponent={({ onClick }) => (
              <button
                type="button"
                title={t("lbl-set-as-template")}
                className="btn btn-link"
                onClick={onClick}
              >
                <IcoTemplate />
              </button>
            )}
          />
        ) : null }

        { (project.isEdited && ! project.isArchived) ? (
          <SaveProject
            projectId={project.id}
            loginModalText={t("text-login-to-archive")}
            shouldArchive={true}
            saveButtonLabel={t("lbl-archive")}
            saveModalText={t("lbl-project-archive")}
            buttonComponent={({ onClick }) => (
              <button
                type="button"
                title={t("lbl-archive")}
                className="btn btn-link"
                onClick={onClick}
              >
                <IcoArchive />
              </button>
            )}
          />
        ) : null }
        { state !== 'template' ? (
          <span>
            { project.backendId ? (
              <button
                type="button"
                title={t("lbl-duplicate")}
                className="btn btn-link"
                onClick={openProject}
              >
                <IcoRightArrow></IcoRightArrow>
              </button>
            ) : (
              <Link className="btn btn-link" to={getProjectLink(project)}>
                <IcoRightArrow></IcoRightArrow>
              </Link>
            )}

          </span>
        ) : null }
      </div>
    </div>
  );
};

export const ProjectListEntry = connect(null, mapDispatchToProps)(Component);
