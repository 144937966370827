import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {MainState} from "../model/main-model";
import {progressSubstepRequest} from "../model/progressBar/progress-bar-actions";
import {SubstepProgressOrder} from "../model/progressBar/progress-bar-service";
import {Substep} from "../model/substeps/substeps-model";
import {
  convertDataToProgressBar,
  StepForRecap
} from "../model/substeps/substeps-service";
import styles from "../styles/modules/subproject-content.module.scss";
import {Link} from "react-router-dom";
import {
  getCurrentOption
} from "../model/progressBar/progress-bar-model";
import {isNil, last} from "ramda";

const mapDispatchToProps = (dispatch: Dispatch) => ({
  progressBarRequest: (stepForRecap: StepForRecap) =>
    dispatch(progressSubstepRequest(stepForRecap))
});

const mapStateToProps = (state: MainState) => ({
  substeps: state.substeps.byId,
  substepsProgressAll: state.progresBar.progresBarState.slice(2),
  substepsProgressDone: state.progresBar.progresBarState.slice(2).filter(
    (p, idx) => p.done === true || (state.progresBar.progresBarState.slice(2)[idx - 1] && state.progresBar.progresBarState.slice(2)[idx - 1].done === true)
  ),
  progressFromStart: state.progresBar.progresBarState
});
type OwnProps = { selectedSubstepId: string; substepCurrent: Substep[] };
type PropsType = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
const Component = ({
                     selectedSubstepId,
                     substeps,
                     substepCurrent,
                     progressBarRequest,
                     substepsProgressDone,
                     substepsProgressAll,
                     progressFromStart
                   }: PropsType) => {
  useEffect(() => {
    progressBarRequest(convertDataToProgressBar(substepCurrent));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubstepId]); // FIXME
  const isActive = (substep: SubstepProgressOrder) => {
    if (substepsProgressDone.length === 0) {
      if (substep.code === "NS") {
        return styles.steps_detail_active;
      } else return styles.steps_detail;
    } else {
      return substep.code ===
      substepsProgressDone[substepsProgressDone.length - 1].code
        ? styles.steps_detail_active
        : styles.steps_detail;
    }
  };

  const getOption = (substep: SubstepProgressOrder) => getCurrentOption(substep, substeps, progressFromStart);
  if (isNil(last(substepCurrent)?.stepLevel) || last(substepCurrent)?.stepLevel === 0 || last(substepCurrent)?.stepLevel === 1) {
    return <div/>;
  } else {
   return (
      <div>
        <div className={styles.steps}>
          {substepsProgressAll.map((substep: SubstepProgressOrder, idx: any) => (
            <div key={`${idx}`} className={isActive(substep)}>
              <div>{getOption(substep) ?
                <Link to={substep.parent}>{substep.label}</Link> :
                <div>{substep.label}</div>}</div>
              <p>{getOption(substep)}</p>
              <span></span>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export const ProgressBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
