import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Dispatch } from "redux";
import { MainUI } from "../components/main-ui";
import AuthRoute from "../containers/authentication/routes/auth-route";
import { MainState } from "../model/main-model";
import { fetchStepsRequest } from "../model/steps/steps-actions";
import { editProfile } from "./authentication/edit-profile";
import { ItemsListBottomBarContainer } from "./items-list-bottom-bar-container";
import { NewProjectBottomBar } from "./new-project-bottom-bar";
import { NewProjectContent } from "./new-project-content";
import { ProjectBottomBar } from "./project-bottom-bar";
import { ProjectItemsList } from "./project-items-list";
import { ProjectRecap } from "./project-recap";
import { ProjectsList } from "./projects-list";
import { RecapBottomBar } from "./recap-bottom-bar";
import { Sidebar } from "./sidebar";
import { SubprojectContent } from "./subproject-content";
import {MenuPopupContext} from "./menu-popup-context";
import {Button, Modal} from "react-bootstrap";
import {Tra} from "../components/tra";

const mapStateToProps = (state: MainState) => ({
  isGuest: state.authentication.guest,
  user: state.authentication.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  requestSteps: () => dispatch(fetchStepsRequest())
});

type OwnProps = {};

type PropsType = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

// const;

const Component = ({ requestSteps }: PropsType) => {
  useEffect(() => {
    requestSteps();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // FIXME

  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const toggleMenuPopup = (shown: boolean) => {
    setShowMenuPopup(shown);
  }

  return (
    <MenuPopupContext.Provider value={{showMenuPopup, toggleMenuPopup}}>
      <MainUI
        aside={
          <Route
            path={[
              "/projects/:projectId/substeps/:substepId",
              "/recap/:projectId",
              ""
            ]}
            component={Sidebar}
          />
        }
        main={
          <main className="main-wrapper">
            <Switch>
              <Route path="/recap/:projectId" component={ProjectRecap} />
              <Route path="/new-project" component={NewProjectContent} />
              <Route
                path={["/projects/:id/substeps/:substep", "/projects/:id"]}
                component={SubprojectContent}
              />
              <AuthRoute path="/profile" component={editProfile} />
              <AuthRoute
                path="/items-list/:projectId"
                component={ProjectItemsList}
              />
              <Route path="/main" exact component={ProjectsList} />
            </Switch>
          </main>
        }
        footer={
          <Switch>
            <Route
              path="/items-list/:projectId"
              component={ItemsListBottomBarContainer}
            />
            <Route path="/new-project" component={NewProjectBottomBar} />
            <Route path="/recap/:projectId" component={RecapBottomBar} />
            <Route
              path={["/projects/:projectId/substeps/:substepId"]}
              component={ProjectBottomBar}
            />
            <Route path="" component={() => <div></div>} />
          </Switch>
        }
      />
    </MenuPopupContext.Provider>
  );
};

export const Main = connect(mapStateToProps, mapDispatchToProps)(Component);
